import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Service: React.FC<Service> = ({ ...service }: Service) => {
  const summaryExcerpt = `${service.summary.slice(0, 130).trimEnd()}...`;
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    const serviceLink = `${window.location.origin}/${service.link}`;
    return (
      <div className="col-12 col-md-4 mb-1">
        <div className="service service-summary">
          <div className="service-image">
            <span dangerouslySetInnerHTML={ { __html: service.emoji } }></span>
          </div>
          <div className="service-content">
            <h2 className="service-title">
              <a href={serviceLink}>{service.title}</a>
            </h2>
            <span className="text-justify">{summaryExcerpt}</span>
          </div>
        </div>
      </div>
    );
  }
};

export default Service;
