import * as React from "react";
import Header from "../components/Header";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

import "../scss/style.scss";
import Footer from "../components/Footer";
import ServicePage from "../components/services/ServicePage";

// markup
const IndexPage = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Services et
        Prestations
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <ServicePage />
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;
