import * as React from "react";
import background from "../../images/main-page.jpg";
import Banner from "../Banner";
import ServicesList from "./ServicesList";

const HomePageStyle: object = {
  backgroundImage: `url(${background})`,
};

// markup
const ServicePage = () => {
  return (
    <>
      <Banner
        title="Prestations"
        content="Notre catalogue de services et prestations"
        background={background}
        position="left"
      />
      <ServicesList />
    </>
  );
};

export default ServicePage;
