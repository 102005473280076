import * as React from "react";
import { Link } from "gatsby";
//import images from '../images/services'
import Service from "./Service";
import ServiceData from "../../data/ServicesData";

// markup
const ServicesList = () => {
  return (
    <div className="container pt-8 pb-8 pb-md-14 pt-md-14">
      <div className="row justify-content-start">
        {ServiceData.map((service) => (
          <Service {...service} />
        ))}
      </div>
    </div>
  );
};

export default ServicesList;
